import React from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from "gatsby";
import ModuleRender from "@components/modules/moduleRender";
import BannerRender from "@components/modules/Banner/BannerRender";
import ArticleText from '@components/articleTextSidebar';
import JobDetailsTemplate from "../../templates/job-details";
const Pages = ({
    // pageContext,
    data,
    location
}) => {
    let myjobslug = ''
    let pathUri_main = location.pathname.split("/about-exclusive-links/real-estate-recruitment-in-uae/");
  
    if ( pathUri_main[1] ) {
      let pathUri_arr = pathUri_main[1].split("?")[0].split("/")
      myjobslug = pathUri_arr[0];
    }
  
    if (myjobslug) {
        return(
          <JobDetailsTemplate myslug={myjobslug}  />
        )
        
    }
    else {
    const { glstrapi: { pages } } = data
    var page = pages[0]
    const breadcrumbs = [
        {...page?.Parent ? { label: page?.Parent?.Pagename, url: `/${page?.Parent?.Slug}`} : ""},
        {...page?.Parent2 ? { label: page?.Parent2?.Pagename, url: `/${page?.Parent2?.Slug}`} : ""},
        {...page?.Parent3 ? { label: page?.Parent3?.Pagename, url: `/${page?.Parent3?.Slug}`} : ""},
        { label: page?.Pagename, url: `/${page?.Slug}` }
    ]
    let bodyClass,
        headerClass;
    if (page?.HeaderTemplate === "Terms") {
        bodyClass = "noBannerImage list-property-page";
        headerClass = "header-dark";
    }

    return (
        <Layout popularSearch={"About_Layout"} bodyClass={bodyClass} headerClass={headerClass}>
            <Seo
                title={page?.Metadata?.title ? page?.Metadata?.title : page?.Title}
                description={page?.Metadata?.description ? page?.Metadata?.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
            />
            {page?.ArticleContent &&
                <ArticleText
                    moduleData={page?.ArticleContent}
                    sidebarData={page?.SidebarContent}
                />
            }
            {page?.BannerImage &&
                <ModuleRender
                    moduleData={page?.BannerImage}
                />
            }
        </Layout>
    )
        }
}

export default Pages

export const query = graphql`
query {
    glstrapi {
        pages(publicationState: LIVE, where: {PageTemplate: "JobLanding"}) {
            id
            Pagename
            Title
            Slug
            PageTemplate
            HeaderTemplate
            Parent {
                Pagename
                Slug
            }
            Parent2 {
                Pagename
                Slug
            }
            Parent3 {
                Pagename
                Slug
            }
            imagetransforms
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerCarousel {
                    ...ImageBannerCarouselFragment
                }
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImageBannerFragment
                    ...ImgBtnFragment
                    ...ImgBtnNormalFragment
                    ...TermsBannerFragment
                }
                ... on GLSTRAPI_ComponentModulesSearchCta {
                    ...PropertySearchFragment
                }
                ... on GLSTRAPI_ComponentModulesIconBox {
                    ...IconBoxFragment
                }
                ... on GLSTRAPI_ComponentModulesPropertySlider {
                    ...PropertySliderFragment
                }
                ... on GLSTRAPI_ComponentModulesBoxCta {
                    ...BoxCtaFragment
                }
                ... on GLSTRAPI_ComponentModulesAboutPeople {
                    ...AboutPeopleFragment
                }
                ... on GLSTRAPI_ComponentModulesAwards {
                    ...AwardsSliderFragment
                }
                ... on GLSTRAPI_ComponentModulesGoogleCta {
                    ...GoogleCtaFragment
                }
                ... on GLSTRAPI_ComponentModulesLatestPodcasts {
                    ...LatestPodcastsFragment
                }
                ... on GLSTRAPI_ComponentModulesLatestVideos {
                    ...LatestVideosFragment
                }
                ... on GLSTRAPI_ComponentModulesStats {
                    ...StatsFragment
                }
                ... on GLSTRAPI_ComponentModulesImageBoxCarousel {
                    ...ImageBoxCarouselFragment
                }
                ... on GLSTRAPI_ComponentModulesFaq {
                    ...FaqFragment
                }
                ... on GLSTRAPI_ComponentModulesJobs {
                    ...JobsFragment
                }
                ... on GLSTRAPI_ComponentModulesAllAreas {
                    ...AllAreasFragment
                }
                ... on GLSTRAPI_ComponentModulesGoogleMaps {
                    ...GoogleMapsFragment
                }
                ... on GLSTRAPI_ComponentModulesAllProjects {
                    ...AllProjectsFragment
                }
                ... on GLSTRAPI_ComponentModulesListProjectStyle {
                    ...ListProjectStyleFragment
                }
            }
            SidebarContent {
                ... on GLSTRAPI_ComponentModulesTeamContact {
                    ...TeamContactFragment
                }
                ... on GLSTRAPI_ComponentModulesSocialShare {
                    ...SocialShareFragment
                }
            }
            ArticleContent {
                ... on GLSTRAPI_ComponentModulesNormalText {
                    ...NormalTextFragment
                }
                ... on GLSTRAPI_ComponentModulesIntroText {
                    ...IntroTextFragment
                }
                ... on GLSTRAPI_ComponentModulesFaq {
                    ...FaqFragment
                }
                ... on GLSTRAPI_ComponentModulesImageGrid {
                    ...ImageGridFragment
                }
                ... on GLSTRAPI_ComponentModulesBuyersGuideForm {
                    ...BuyersGuideFormFragment
                }
                ... on GLSTRAPI_ComponentModulesBookValuation {
                    ...BookValuationFragment
                }
                ... on GLSTRAPI_ComponentModulesManagementGuideForm {
                    ...ManagementGuideFormFragment
                }
                ... on GLSTRAPI_ComponentModulesTableList {
                    ...TableListFragment
                }
                ... on GLSTRAPI_ComponentModulesAccordion {
                    ...AccordionFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
    }
}
`